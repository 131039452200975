<template>
  <component
    :is="
      {
        1: EmptyCard1,
        2: EmptyCard2,
        3: EmptyCard3,
        4: EmptyCard4,
      }[randomNumber]
    "
  />
</template>

<script lang="ts" setup>
import { EmptyCard1, EmptyCard2, EmptyCard3, EmptyCard4 } from "#components";

const randomNumber = Math.ceil(Math.random() * 4);
</script>
